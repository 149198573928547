import React, {Component} from 'react';
import {I18nextProvider} from 'react-i18next';
import {I18nProvider} from 'gatsby-i18n';

import setupI18next from './setupI18next';
import * as PropTypes from 'prop-types';

const withI18next = () => Comp => {

  class I18n extends Component {
    constructor(props) {
      super(props);

      const {pageContext} = props;

      this.i18n = setupI18next(pageContext);
      this.activateLanguage();
    }

    activateLanguage() {
      const {pageContext} = this.props;
      this.i18n.changeLanguage(pageContext.lng);
    }

    componentDidUpdate(prevProps) {
      if (this.props.pageContext.lng !== prevProps.pageContext.lng) {
        this.activateLanguage();
      }
    }

    render() {
      return (
        <I18nextProvider i18n={this.i18n}>
          <I18nProvider {...this.props.pageContext}>
            <Comp {...this.props} lng={this.props.pageContext.lng}/>
          </I18nProvider>
        </I18nextProvider>
      );
    }
  }

  I18n.propTypes = {
    pageContext: PropTypes.shape({
      lng: PropTypes.string.isRequired,
    }).isRequired,
  };

  return I18n;
};

export default withI18next;
