import * as React from 'react';
import {Link} from '../../../plugins/gatsby-plugin-i18next';
// @ts-ignore
import {ReactComponent as Logo} from '../../images/logo.svg';
import LanguageSwitcher from './languageSwitcher';

const styles = require('./header.module.scss');

interface Props {
  t?: (s: string) => string;
  siteTitle: string;
  className: string;
}

const Header = ({siteTitle, className}: Props) => (
  <div className={className}>
    <div className={styles.header}>
      <Logo className={styles.logo}/>
      <h1 style={{margin: 0}}>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1>
    </div>
    <div
      style={{
        position: 'absolute',
        right: 20,
        top: 20,
      }}
    >{
      <LanguageSwitcher/>
    }
    </div>
  </div>
);

export default Header;
