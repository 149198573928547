import * as React from 'react';
import {Link} from '../../../plugins/gatsby-plugin-i18next';
import {getPageStyleClass} from '../../utils/styles';

const styles = require('./tabBarItem.module.scss');

interface Props {
  title: string;
  href: string;
  page: string;
}

function TabBarItem(props: Props) {
  const {title, href, page} = props;
  const styleClass = getPageStyleClass(styles, page);
  return (
    <Link
      to={href}
      tabIndex={0}
      activeClassName={styles.selected}
      className={styles.tabBarItem + ' ' + styleClass + ' '}
    >
      {title}
    </Link>
  );
}

export default TabBarItem;
