import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from '../../../plugins/gatsby-plugin-i18next';
import constants from '../../utils/constants';

const styles = require('./footer.module.scss');

interface Props {
  className: string;
  data: {
    site: {
      siteMetadata: {
        author: string,
        authorUrl: string,
      },
    },
  };
}

function Footer(props: Props) {

  const {className, data} = props;
  const {t} = useTranslation();

  return (
    <div className={styles.footer + ' ' + className}>
      © 2019 <a target="_blank" href={data.site.siteMetadata.authorUrl}>{data.site.siteMetadata.author}</a>

      <div className={styles.flushRight}>
        <Link to={'/contact'}>{t(constants.PAGE_CONTACT_US)}</Link>
        &nbsp;|&nbsp;
        <Link to={'/privacyPolicy'}>{t(constants.PAGE_PRIVACY_POLICY)}</Link>
      </div>
    </div>
  );
}

export default (props: any) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            author
            authorUrl
          }
        }
      }
    `}
    render={(data) => <Footer
      data={data}
      className={props.className}
    />}
  />
);
