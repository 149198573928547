import {graphql, StaticQuery} from 'gatsby';
import * as React from 'react';
import {WithTranslation, withTranslation} from 'react-i18next';
// @ts-ignore
import MetaTags from 'react-meta-tags';
import Footer from '../../components/footer';
import Header from '../../components/header';
import TabBar from '../../components/tabBar';
// @ts-ignore
import logoOg from '../../images/logo-og.png';
import './bootstrap.scss';

const styles = require('./main.module.scss');

interface Props extends WithTranslation {
  currentPage: string;
  children: any;
  data: {
    site: {
      siteMetadata: {
        shortTitle: string,
        title: string,
      },
    },
  };
}

class Layout extends React.Component<Props> {

  public render() {

    const {children, data, currentPage, t} = this.props;

    return (
      <div className={styles.layout}>
        <MetaTags>
          <title>{data.site.siteMetadata.shortTitle + ': ' + t(currentPage + '-title')}</title>
          <meta name="description" content={t(currentPage + '-meta-desc', {title: data.site.siteMetadata.title})}/>
          <meta name="keywords" content={t(currentPage + '-meta-keywords', {title: data.site.siteMetadata.title})}/>
          <meta name="og:image" content={logoOg}/>
          <meta name="google-site-verification" content="Rt3GOFdv--NM2qvE-sXHFZJEnoe4PMclf4RRyeKgcUw"/>
          <meta name="msvalidate.01" content="EB1F9E9B6FB5230BB2C020024739C415"/>
        </MetaTags>
        <Header siteTitle={data.site.siteMetadata.title} className={styles.header}/>
        <TabBar currentPage={currentPage} className={styles.tabBar}/>
        <div className={styles.main}>
          {children}
        </div>
        <Footer className={styles.footer}/>
      </div>
    );
  }
}

export default withTranslation()((props: any) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title,
            shortTitle
          }
        }
      }
    `}
    render={(data) => <Layout
      data={data}
      children={props.children}
      currentPage={props.currentPage}
      t={props.t}
      i18n={props.i18n}
    />}
  />
));
