import * as React from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import constants from '../../utils/constants';
import {getPageStyleClass} from '../../utils/styles';
import TabBarItem from '../tabBarItem';

const styles = require('./tabBar.module.scss');

interface Props extends WithTranslation {
  currentPage: string;
  className: string;
}

class TabBar extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  public render() {
    const {currentPage, className, t} = this.props;
    const pageStyleClass = getPageStyleClass(styles, currentPage);
    return (
      <div className={styles.tabBar + ' ' + className + ' ' + pageStyleClass}>
        <TabBarItem
          title={t(constants.PAGE_ABOUT_US)}
          href={'/'}
          page={constants.PAGE_ABOUT_US}
        />
        <TabBarItem
          title={t(constants.PAGE_JSON)}
          href={'/json/'}
          page={constants.PAGE_JSON}
        />
        <TabBarItem
          title={t(constants.PAGE_XML)}
          href={'/xml/'}
          page={constants.PAGE_XML}
        />
        <TabBarItem
          title={t(constants.PAGE_SQL)}
          href={'/sql/'}
          page={constants.PAGE_SQL}
        />
        <TabBarItem
          title={t(constants.PAGE_UUID)}
          href={'/uuid/'}
          page={constants.PAGE_UUID}
        />
      </div>
    );
  }
}

export default withTranslation()(TabBar);
