import constants from './constants';

export function getPageStyleClass(styles: any, page: string) {
  switch (page) {
    case constants.PAGE_ABOUT_US:
      return styles.aboutUs;
    case constants.PAGE_JSON:
      return styles.json;
    case constants.PAGE_UUID:
      return styles.uuid;
    case constants.PAGE_SQL:
      return styles.sql;
    case constants.PAGE_XML:
      return styles.xml;
    default:
      return styles.aboutUs;
  }
}
