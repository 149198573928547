import React from 'react';
import {Language} from '../../../plugins/gatsby-plugin-i18next';

const LanguageSwitcher = ({changeLng, lng}) => (
  <select value={lng} onChange={(e) => changeLng(e.target.value)}>
    <option value={'en'}>English</option>
    <option value={'zh'}>中文</option>
  </select>);

export default props => (
  <Language>{lngProps => <LanguageSwitcher {...props} {...lngProps} />}</Language>
);
