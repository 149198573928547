export default Object.freeze({
  PAGE_NOT_FOUND: 'PAGE_NOT_FOUND',
  PAGE_ABOUT_US: 'PAGE_ABOUT_US',
  PAGE_PRIVACY_POLICY: 'PAGE_PRIVACY_POLICY',
  PAGE_CONTACT_US: 'PAGE_CONTACT_US',
  PAGE_THANK_YOU: 'PAGE_THANK_YOU',
  PAGE_JSON: 'PAGE_JSON',
  PAGE_UUID: 'PAGE_UUID',
  PAGE_SQL: 'PAGE_SQL',
  PAGE_XML: 'PAGE_XML',
});
