/* globals require */
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

const enMessages = require('../../locale/en/messages.json');
const zhMessages = require('../../locale/zh/messages.json');

function setupI18next({ fallbackLng, debug }) {
  const resources = {
    en: {
      translation: enMessages,
    },
    zh: {
      translation: zhMessages,
    },
  };
  i18next
    .use(initReactI18next)
    .init({
      debug,
      fallbackLng,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },
      resources,
    });

  return i18next;
}

export default setupI18next;
