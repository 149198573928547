import hljs from 'highlight.js';
import * as React from 'react';
import {ChangeEvent, FormEvent} from 'react';
import ContentEditable from 'react-contenteditable';
import {withTranslation, WithTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import Switch from 'react-switch';
import {Alert, Button, ButtonGroup, Form, FormGroup, Label} from 'reactstrap';
import {processFormat} from '../../../redux/actions';
import Analytics from '../../../utils/analytics';

const sanitizeHtml = require('sanitize-html');
const _ = require('lodash');
const styles = require('./formatter.module.scss');

const WORKER_SIZE = 10000;

interface Props extends WithTranslation {
  currentPage: string;
  language: string;
  format: (s: string, indent: string) => string;
  className?: string;
}

function mapDispatchToProps(dispatch: any): any {
  return {
    processFormat: (language: string, data: string) => dispatch(processFormat(language, data)),
  };
}

function highlight_worker_function() {
  onmessage = (event) => {
    importScripts(location.origin + '/js/highlightjs/highlight.pack.js');
    // @ts-ignore
    const workerHljs = self.hljs;
    const result = workerHljs.highlightAuto(event.data);
    postMessage(result.value);
    close();
  };
}

enum SourceState {
  Empty,
  Invalid,
  Formatted,
  Copied,
}

class ConnectedForm extends React.Component<Props, any> {

  private readonly contentEditable: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    this.state = {
      background: 'dark',
      data: '',
      highlight: true,
      indent: '2',
      sourceState: SourceState.Empty,
    };

    this.contentEditable = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleFormat = this.handleFormat.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.setHighlight = this.setHighlight.bind(this);
    this.selectAndCopy = this.selectAndCopy.bind(this);
  }

  public render() {
    const t = this.props.t;
    const currentPage = this.props.currentPage;

    let message;
    let messageColor;
    let showCopyButton = false;

    switch (this.state.sourceState) {
      case SourceState.Invalid:
        message = t('FORMATTER-source-invalid');
        messageColor = 'danger';
        break;
      case SourceState.Formatted:
        message = t('FORMATTER-source-formatted');
        messageColor = 'success';
        showCopyButton = true;
        break;
      case SourceState.Copied:
        message = t('FORMATTER-source-copied');
        messageColor = 'success';
        break;
      case SourceState.Empty:
      default:
        message = t('FORMATTER-source-empty');
        messageColor = 'primary';
        break;
    }

    return (
      <div className={styles.formatter + ' ' + this.props.className}>
        <Alert color={messageColor}>
          <span dangerouslySetInnerHTML={{__html: message}}/>
          &nbsp;
          <Button style={{display: showCopyButton ? 'inline-block' : 'none'}} onClick={this.selectAndCopy}>
            {t('FORMATTER-source-copy-button')}
          </Button>
        </Alert>
        <div className={styles.main}>
          <div className={styles.controls}>
            <Form>
              <FormGroup>
                <Label>
                  {t('FORMATTER-background')}
                </Label>
                <ButtonGroup>
                  <Button
                    size="sm"
                    onClick={() => this.setBackground('light')}
                    active={this.state.background === 'light'}
                    color="secondary"
                  >
                    {t('FORMATTER-background-light')}
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.setBackground('dark')}
                    active={this.state.background === 'dark'}
                    color="secondary"
                  >
                    {t('FORMATTER-background-dark')}
                  </Button>
                </ButtonGroup>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="highlight-switch">
                  {t('FORMATTER-highlight')}
                </Label>
                <Switch
                  id="highlight-switch"
                  checked={this.state.highlight}
                  onChange={this.setHighlight}
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={48}
                />
              </FormGroup>
              <FormGroup>
                <Label>{t('FORMATTER-indent')}</Label>
                <ButtonGroup>
                  <Button
                    size="sm"
                    onClick={() => this.setIndent('2')}
                    active={this.state.indent === '2'}
                    color="secondary"
                  >
                    2
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.setIndent('4')}
                    active={this.state.indent === '4'}
                    color="secondary"
                  >
                    4
                  </Button>
                  <Button
                    size="sm"
                    onClick={() => this.setIndent('tab')}
                    active={this.state.indent === 'tab'}
                    color="secondary"
                  >
                    tab
                  </Button>
                </ButtonGroup>
              </FormGroup>
              <Button
                className={styles.submitButton}
                color="primary"
                onClick={this.handleFormat}
              >
                {t('FORMATTER-format')}
              </Button>
            </Form>
          </div>
          <div className={styles.content}>
            <ContentEditable
              tagName="pre"
              className={styles.source + ' ' + (this.state.highlight ? 'hljs' : styles[this.state.background])}
              innerRef={this.contentEditable}
              html={this.state.data} // innerHTML of the editable div
              disabled={false}       // use true to disable editing
              onChange={this.handleChange}
            />
          </div>
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
    this.contentEditable.current!.onpaste = this.handlePaste;
    this.contentEditable.current!.spellcheck = false;
    this.setHljsStylesheet();
  }

  public componentDidMount(): void {
    this.setHljsStylesheet();
    document.execCommand('defaultParagraphSeparator', false, 'br');
  }

  private format() {

    let indent;
    switch (this.state.indent) {
      case '2':
        indent = '  ';
        break;
      case '4':
        indent = '    ';
        break;
      case 'tab':
        indent = '\t';
        break;
      default:
        throw Error('Unknown indent [' + indent + ']');
    }

    const escaped = sanitizeHtml(this.state.data, {
      allowedAttributes: {},
      allowedTags: [],
    });
    // restore the unescaped data. This should be exactly what the user types in.
    const sanitizedData = _.unescape(escaped);

    let formattedData;
    try {
      formattedData = this.props.format(sanitizedData, indent);
    } catch (e) {
      /*
       * If the string is invalid, we set our state to the sanitized version of the data.
       * This avoids putting markups that are not generated by highlight.js
       */
      let sourceState;
      if (_.trim(sanitizedData).length !== 0) {
        sourceState = SourceState.Invalid;
      } else {
        sourceState = SourceState.Empty;
      }
      this.setState({data: this.getHtmlSafeString(sanitizedData), sourceState});
      Analytics.gtag('event', 'format-bad-data', this.getGtagEventData());
      return;
    }

    Analytics.gtag('event', 'format-good-data', this.getGtagEventData());

    this.setState({data: this.getHtmlSafeString(formattedData)});

    // save the formatted string into the store
    // we don't want to save the highlight version in the store though.
    // @ts-ignore
    // Cannot add "processFormat: (language: string, data: string) => void;" to interface or else we'd
    // get another tslint error in the caller.
    this.props.processFormat(this.props.language, formattedData);

    if (this.state.highlight) {
      this.highlightData(formattedData);
    }

    this.setState({sourceState: SourceState.Formatted});
  }

  private handleChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({data: event.target.value});
  }

  private handlePaste() {
    const that = this;
    setTimeout(() => {
      that.format();
    }, 0);
  }

  private handleFormat(event: FormEvent) {
    event.preventDefault();
    this.format();
  }

  private highlightData(formattedData: string) {
    if (typeof (Worker) !== undefined && formattedData.length > WORKER_SIZE) {
      const workerFunction = new Blob(['(' + highlight_worker_function.toString() + ')()'], {type: 'text/javascript'});
      const localWorkerURL = URL.createObjectURL(workerFunction);
      const worker = new Worker(localWorkerURL);
      worker.onmessage = (workerEvent) => {
        this.setState({data: workerEvent.data});
      };
      worker.postMessage(formattedData); // start worker
    } else {
      const result = hljs.highlightAuto(formattedData);
      this.setState({data: result.value});
    }
  }

  private setIndent(level: string) {
    this.setState({indent: level}, () => this.format());
  }

  private setBackground(background: string) {
    this.setState({background});
  }

  private setHighlight(value: boolean) {
    this.setState({highlight: value}, () => this.format());
  }

  private getHtmlSafeString(s: string) {
    return _.escape(s);
  }

  private setHljsStylesheet() {

    const hljsHref = this.state.background === 'dark' ?
      '/js/highlightjs/styles/rainbow.css' :
      '/js/highlightjs/styles/tomorrow.css';
    const hljsLinkId = 'hljs-css';
    let hljsCss = document.getElementById(hljsLinkId) as HTMLLinkElement | null;

    if (hljsCss === null) {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');
      link.id = hljsLinkId;
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = hljsHref;
      link.media = 'all';
      head.appendChild(link);
      hljsCss = link;
    }

    if (hljsCss.href.indexOf(hljsHref) < 0) {
      hljsCss.href = hljsHref;
    }
  }

  private selectAndCopy(e: React.MouseEvent<HTMLButtonElement>) {
    const element = document.querySelector('.' + styles.content); // this.contentEditable.current;
    window.getSelection().selectAllChildren(element!);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.setState({sourceState: SourceState.Copied});
    Analytics.gtag('event', 'select-and-copy', this.getGtagEventData());
  }

  private getGtagEventData() {
    const indent = this.state.indent;
    const highlight = this.state.highlight;
    const background = this.state.background;
    const currentPage = this.props.currentPage;
    return {
      event_category: currentPage,
      event_label: JSON.stringify({currentPage, background, highlight, indent}),
    };
  }

}

const Formatter = connect(null, mapDispatchToProps)(ConnectedForm);

export default withTranslation()(Formatter);
