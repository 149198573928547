import * as React from 'react';
// @ts-ignore
import format from 'xml-formatter';
import withI18next from '../../../plugins/gatsby-plugin-i18next/withI18next';
import Formatter from '../../components/tools/formatter';
import Layout from '../../layouts/tools';
import constants from '../../utils/constants';

const _ = require('lodash');
const styles = require('./xml.module.scss');

function parseXmlToDom(s: string) {
  const parser = new DOMParser();
  const parsererrorNS = parser.parseFromString('INVALID', 'text/xml')
    .getElementsByTagName('parsererror')[0].namespaceURI;
  const dom = parser.parseFromString(s, 'text/xml');
  if (dom.getElementsByTagNameNS(parsererrorNS!, 'parsererror').length > 0) {
    throw new Error('Error parsing XML');
  }
  return dom;
}

function formatter(s: string, indent: string) {
  const dom = parseXmlToDom(s);
  const xml = new XMLSerializer().serializeToString(dom);
  const removeEmptySpaces = xml.replace(/>\s*/g, '>').replace(/\s*</g, '<');
  return format(removeEmptySpaces, {
    indentation: indent,
    stripComments: false,
  });
}

const Page = () => (
  <Layout currentPage={constants.PAGE_XML}>
    <Formatter
      className={styles.formatter}
      currentPage={constants.PAGE_XML}
      language={'xml'}
      format={formatter}
    />
  </Layout>
);

export default withI18next()(Page);
